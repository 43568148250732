(function(angular) {
  'use strict';

  angular.module('esn.calendar')
    .controller('CalEventViewInternalUserController', CalEventViewInternalUserController);

  function CalEventViewInternalUserController() {
    // eslint-disable-next-line no-warning-comments
    // TODO IN EVENT FORM REFACTOR
  }
})(angular);
